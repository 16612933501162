import { useMutation } from '@tanstack/react-query'
import BUSINESS_TRAVELLER_BUSINESS_EMPLOYEE_COUNT_OPTIONS from 'businessTraveller/constants/businessTravellerBusinessEmployeeCount'
import requestDemo from 'businessTraveller/api/requestPostRequestDemo'
import PhoneInput from 'components/Common/Form/Input/PhoneInput'
import Select from 'components/Common/Form/Input/Select'
import TextInput from 'components/Common/Form/Input/TextInput'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import TextButton from 'components/Luxkit/Button/TextButton'
import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { formToObject } from 'lib/forms/formToObject'
import { rem } from 'polished'
import React, { FormEventHandler, useCallback, useState } from 'react'
import styled from 'styled-components'

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${rem(20)};

  ${mediaQueryUp.tablet} {
    grid-template-areas: "firstName phone"
                        "email email"
                        "employees bookingProcess";

    .grid-firstName {
      grid-area: firstName;
    }
    .grid-phone {
      grid-area: phone;
    }
    .grid-email {
      grid-area: email;
    }
    .grid-employees{
      grid-area: employees;
    }
    .grid-bookingProcess {
      grid-area: bookingProcess;
    }
  }
`

export interface RequestDemoData {
  currentBookingProcess: string;
  email: string;
  firstName: string;
  numberOfEmployees: string;
  phone: string;
}

export interface FormData {
  currentBookingProcess: string;
  email: string;
  firstName: string;
  numberOfEmployees: string;
  phone: { phone: string, prefix: string };
}

function mapFormData(formData: FormData): RequestDemoData {
  return {
    currentBookingProcess: formData.currentBookingProcess,
    email: formData.email,
    firstName: formData.firstName,
    numberOfEmployees: formData.numberOfEmployees,
    phone: `${formData.phone.prefix} ${formData.phone.phone}`,
  }
}

export default function BusinessTravellerRequestDemoModal() {
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [finished, setFinished] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const emailSendMutation = useMutation({
    mutationFn: async(userData: RequestDemoData) => {
      return requestDemo(userData)
    },
    onSuccess: async() => {
      setFinished(true)
    },
    onError: async(error: App.ApiCallFailure) => {
      setErrorMsg(error.message)
      setIsError(true)
      setIsSubmitted(false)
    },
  })

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>((e) => {
    e.preventDefault()
    setIsSubmitted(true)
    const formData = formToObject<FormData>(e.currentTarget)
    const mappedFormData = mapFormData(formData)
    emailSendMutation.mutate(mappedFormData)
  }, [emailSendMutation])

  return (<Modal size={finished ? 'S' : 'L'} title={finished ? 'Thank you!' : 'Request a demo'}>
    {isError && <MessageBanner kind="critical" description={`Something went wrong while setting up your booking. Error message: ${errorMsg}`}/>}
    {finished && <BodyText variant="medium">Your request has been successfully submitted. Our Business Travel Specialist will reach out to you shortly.</BodyText>}
    {!finished && (<Group direction="vertical" gap={20}>
      <BodyText variant="medium">
        Our Business Travel Specialist will reach out to you to arrange a tailored meeting
      </BodyText>

      <form onSubmit={handleSubmit}>
        <InputGrid>
          <TextInput
            className="grid-firstName"
            invalidErrorMessage="Invalid characters. Please use only letters and spaces"
            label="First name"
            maxLength={100}
            minLength={2}
            name="firstName"
            pattern="^[A-Za-z ]+$"
            placeholder="Your name"
            required
            requiredErrorMessage="First name is required"
          />

          <PhoneInput
            className="grid-phone"
            defaultPhonePrefix="+61"
            label="Phone number"
            name="phone"
            placeholder="0400 000 000"
            required
            requiredErrorMessage="Phone number is required"
          />

          <TextInput
            className="grid-email"
            invalidErrorMessage="Invalid email address"
            label="Email address"
            maxLength={100}
            name="email"
            placeholder="Enter your business email"
            required
            requiredErrorMessage="Email address is required"
            type="email"
          />

          <Select
            className="grid-employees"
            label="Number of employees"
            name="numberOfEmployees"
            placeholder="e.g. 1 - 20"
            required
            requiredErrorMessage="Number of employees is required"
          >
            {BUSINESS_TRAVELLER_BUSINESS_EMPLOYEE_COUNT_OPTIONS.map(
              ([key, label]) =>
                <option key={key} value={key}>{label}</option>,
            )}
          </Select>
          <Select
            className="grid-bookingProcess"
            label="Current booking process"
            name="currentBookingProcess"
            placeholder="e.g. Internet self service"
            required
            requiredErrorMessage="Current booking process is required"
          >
            <option key="self" value="self-service">Internet Self Service</option>
            <option key="managed" value="travel-manager">Travel Manager</option>
            <option key="none" value="none">Not Currently Travelling</option>
            <option key="other" value="other">Other</option>
          </Select>
        </InputGrid>
        <Group direction="horizontal" horizontalAlign="end">
          <TextButton disabled={isSubmitted} fit="mobile-full-width" kind="primary" size="large" type="submit">Submit</TextButton>
        </Group>
      </form>
    </Group>)}
  </Modal>)
}
