import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineShieldIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19.63 3.65a.9999.9999 0 0 0-.84-.2 8.0001 8.0001 0 0 1-6.22-1.27 1.0001 1.0001 0 0 0-1.14 0 7.9997 7.9997 0 0 1-6.22 1.27 1 1 0 0 0-.84.2 1 1 0 0 0-.37.78v7.45a9 9 0 0 0 3.77 7.33l3.65 2.6a1 1 0 0 0 1.16 0l3.65-2.6A9.0006 9.0006 0 0 0 20 11.88V4.43a1.0005 1.0005 0 0 0-.37-.78ZM18 11.88a6.9997 6.9997 0 0 1-2.93 5.7L12 19.77l-3.07-2.19A6.9991 6.9991 0 0 1 6 11.88v-6.3a10 10 0 0 0 6-1.39 10 10 0 0 0 6 1.39v6.3Z"/>
  </SvgIcon>
}

export default LineShieldIcon
