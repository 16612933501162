import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineShopIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M22 5H2a1 1 0 0 0-1 1v4a3.0003 3.0003 0 0 0 2 2.82V22a1 1 0 0 0 1 1h16a1.0001 1.0001 0 0 0 1-1v-9.18A3.0003 3.0003 0 0 0 23 10V6a1.0002 1.0002 0 0 0-1-1Zm-7 2h2v3a1.0001 1.0001 0 0 1-2 0V7Zm-4 0h2v3a1.0001 1.0001 0 0 1-2 0V7ZM7 7h2v3a1 1 0 1 1-2 0V7Zm-3 4a1.0002 1.0002 0 0 1-1-1V7h2v3a1 1 0 0 1-1 1Zm10 10h-4v-2a2 2 0 0 1 4 0v2Zm5 0h-3v-2a3.9996 3.9996 0 0 0-1.1716-2.8284 3.9996 3.9996 0 0 0-5.6568 0A3.9998 3.9998 0 0 0 8 19v2H5v-8.18a3.1697 3.1697 0 0 0 1-.6 2.9997 2.9997 0 0 0 4 0 2.9998 2.9998 0 0 0 4 0 2.9998 2.9998 0 0 0 4 0 3.1698 3.1698 0 0 0 1 .6V21Zm2-11a1.0001 1.0001 0 0 1-2 0V7h2v3ZM4.3 3H20a1 1 0 1 0 0-2H4.3a1 1 0 0 0 0 2Z"/>
  </SvgIcon>
}

export default LineShopIcon
