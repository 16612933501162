import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineCoinsIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M21.22 12a2.9997 2.9997 0 0 0 .78-2 3 3 0 0 0-3-3h-5.18a3.0006 3.0006 0 0 0-.3765-2.7286A2.9999 2.9999 0 0 0 11 3H5a3 3 0 0 0-3 3 3 3 0 0 0 .78 2 3 3 0 0 0 0 4 3 3 0 0 0 0 4A2.9998 2.9998 0 0 0 2 18a3 3 0 0 0 3 3h14a2.9995 2.9995 0 0 0 2.7259-1.7826A3.0006 3.0006 0 0 0 21.22 16a2.9998 2.9998 0 0 0 0-4ZM11 19H5a1.0002 1.0002 0 0 1-.7071-1.7071A1.0002 1.0002 0 0 1 5 17h6a1.0001 1.0001 0 0 1 0 2Zm0-4H5a1.0002 1.0002 0 0 1-.7071-1.7071A1.0002 1.0002 0 0 1 5 13h6a1.0001 1.0001 0 0 1 0 2Zm0-4H5a1.0002 1.0002 0 0 1-.7071-1.7071A1 1 0 0 1 5 9h6a1 1 0 1 1 0 2Zm0-4H5a1 1 0 0 1 0-2h6a1 1 0 1 1 0 2Zm8.69 11.71A.9299.9299 0 0 1 19 19h-5.18a2.87 2.87 0 0 0 0-2H19a1.0001 1.0001 0 0 1 1 1 .9995.9995 0 0 1-.31.71Zm0-4A.9299.9299 0 0 1 19 15h-5.18a2.87 2.87 0 0 0 0-2H19a1.0001 1.0001 0 0 1 1 1 .9995.9995 0 0 1-.31.71Zm0-4A.9299.9299 0 0 1 19 11h-5.18a2.87 2.87 0 0 0 0-2H19a1 1 0 0 1 1 1 .9995.9995 0 0 1-.31.71Z"/>
  </SvgIcon>
}

export default LineCoinsIcon
