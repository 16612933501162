import List from 'components/Common/List/List'
import Pane from 'components/Common/Pane/Pane'
import Group from 'components/utils/Group'
import SvgIcon from 'components/Luxkit/SvgIcon'
import Heading from 'components/Luxkit/Typography/Heading'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const ContainerGroup = styled(Group)`
  padding: ${rem(20)};
`

interface Props {
  bulletPoints: Array<string>
  IconComponent: typeof SvgIcon
  title: string
}

function BusinessTravellerSolutionTile(props: Props) {
  const {
    IconComponent,
    title,
    bulletPoints,
  } = props

  return <Pane>
    <ContainerGroup direction="vertical" gap={16}>
      <IconComponent />
      <Group direction="vertical" gap={12}>
        <Heading variant="heading5">{title}</Heading>
        <List spaced>
          {bulletPoints.map((bulletPoint) => <li
            key={bulletPoint}
          >
            {bulletPoint}
          </li>)}
        </List>
      </Group>
    </ContainerGroup>
  </Pane>
}

export default BusinessTravellerSolutionTile
