import request from 'api/requestUtils'
import { RequestDemoData } from 'businessTraveller/components/demo-modal/BusinessTravellerRequestDemoModal'

async function requestDemo(businessData: RequestDemoData) {
  return request.post(
    '/api/business-traveller/request-demo',
    businessData,
  )
}

export default requestDemo
