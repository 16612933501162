import Group from 'components/utils/Group'
import React, { ComponentProps } from 'react'
import HorizontalContainerWithFadedOverflow from 'components/Common/HorizontalContainerWithFadedOverflow'
import BusinessTravellerAdvantageTile from './BusinessTravellerAdvantageTile'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'

const ContainerGroup = styled(Group)`
  > * {
    width: ${rem(260)};

    ${mediaQueryUp.tablet} {
      width: unset;
      flex: 1;
    }
  }
`

interface Props {
  advantages: Array<ComponentProps<typeof BusinessTravellerAdvantageTile>>
}

function BusinessTravellerAdvantagesGroup(props: Props) {
  const { advantages } = props

  return <HorizontalContainerWithFadedOverflow>
    <ContainerGroup direction="horizontal" gap={16} tabletGap={24}>
      {advantages.map(benefit => <BusinessTravellerAdvantageTile
        key={benefit.title}
        IconComponent={benefit.IconComponent}
        title={benefit.title}
        description={benefit.description}
      />)}
    </ContainerGroup>
  </HorizontalContainerWithFadedOverflow>
}

export default BusinessTravellerAdvantagesGroup
