import Group from 'components/utils/Group'
import SvgIcon from 'components/Luxkit/SvgIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import React from 'react'

interface Props {
  IconComponent: typeof SvgIcon
  title: string
  description: string
}

function BusinessTravellerAdvantageTile(props: Props) {
  const { IconComponent, title, description } = props

  return <Group direction="vertical" gap={12}>
    <IconComponent size="L" />
    <Group direction="vertical" gap={4}>
      <BodyText variant="medium" weight="bold">{title}</BodyText>
      <BodyText variant="medium">{description}</BodyText>
    </Group>
  </Group>
}

export default BusinessTravellerAdvantageTile
