import LayoutContainer from 'components/Common/LayoutContainer'
import Pane from 'components/Common/Pane'
import ResponsiveImage from 'components/Common/ResponsiveImage'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'

export const BusinessTravellerHeroSectionContentContainer = styled(LayoutContainer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};

  ${mediaQueryUp.tablet} {
    padding-top: ${rem(80)};
    padding-bottom: ${rem(80)};
  }
`

const HeroLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(40)};

  ${mediaQueryUp.desktop} {
    display: grid;
    align-items: center;
    grid-template: 'content image' auto / 1fr ${rem(500)};
    gap: ${rem(80)};

    &.is-inverted {
      grid-template: 'image content' auto / ${rem(500)} 1fr;
    }
  }

  > .content-area {
    grid-area: content;
  }

  > .image-area {
    grid-area: image;
    align-self: center;

    ${mediaQueryUp.desktop} {
      max-width: ${rem(500)};
    }
  }
`

interface Props {
  desktopImageId: string
  mobileImageId: string
  isInverted?: boolean
  isReversed?: boolean
  type?: 'light-grey'
}

function BusinessTravellerHeroSection(props: PropsWithChildren<Props>) {
  const {
    children,
    desktopImageId,
    mobileImageId,
    isInverted,
    isReversed,
    type,
  } = props

  return <Pane type={type} paletteType={isInverted ? 'inverse' : 'default'}>
    <BusinessTravellerHeroSectionContentContainer>
      <HeroLayout className={cn({ 'is-inverted': isReversed })}>
        <div className="content-area">
          {children}
        </div>

        <CSSBreakpoint max="tablet" className="image-area">
          <ResponsiveImage id={mobileImageId} mobileWidth="100vw" fit="center" />
        </CSSBreakpoint>
        <CSSBreakpoint min="desktop" className="image-area">
          <ResponsiveImage id={desktopImageId} mobileWidth="500px" fit="center" />
        </CSSBreakpoint>
      </HeroLayout>
    </BusinessTravellerHeroSectionContentContainer>
  </Pane>
}

export default BusinessTravellerHeroSection
