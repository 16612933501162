import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineGraphBarIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M6 13H2a1.0002 1.0002 0 0 0-1 1v8a1 1 0 0 0 1 1h4a1.0002 1.0002 0 0 0 1-1v-8a1 1 0 0 0-1-1Zm-1 8H3v-6h2v6ZM22 9h-4a1 1 0 0 0-1 1v12a1.0001 1.0001 0 0 0 1 1h4a1.0001 1.0001 0 0 0 1-1V10a1.0002 1.0002 0 0 0-1-1Zm-1 12h-2V11h2v10ZM14 1h-4a1 1 0 0 0-1 1v20a1 1 0 0 0 1 1h4a1.0001 1.0001 0 0 0 1-1V2a1.0002 1.0002 0 0 0-1-1Zm-1 20h-2V3h2v18Z"/>
  </SvgIcon>
}

export default LineGraphBarIcon
