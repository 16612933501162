import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineCardAtmIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 4.5H5a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3Zm1 12a1.0001 1.0001 0 0 1-1 1H5a1.0002 1.0002 0 0 1-1-1v-9a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v9Zm-4-6a2.9999 2.9999 0 0 0-1.51.42 3.0003 3.0003 0 0 0-3.0154-.027A3.0007 3.0007 0 0 0 9.9591 13.5a2.9998 2.9998 0 0 0 4.5309 2.58 3.001 3.001 0 0 0 2.6589.1997 2.9997 2.9997 0 0 0 1.8143-3.3647A3 3 0 0 0 16 10.5Zm-2.83 4a1.0053 1.0053 0 0 1-.17 0 1.0001 1.0001 0 0 1 0-2 1.0053 1.0053 0 0 1 .17 0c-.2263.62-.2263 1.3 0 1.92v.08Zm2.83 0a1 1 0 1 1-.0002-2 1 1 0 0 1 .0002 2Z"/>
  </SvgIcon>
}

export default LineCardAtmIcon
