import LayoutContainer from 'components/Common/LayoutContainer'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import { rem } from 'polished'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import BusinessTravellerAdvantagesGroup from './sections/BusinessTravellerAdvantagesGroup'
import BusinessTravellerAdvantageTile from './sections/BusinessTravellerAdvantageTile'
import LineAwardIcon from 'components/Luxkit/Icons/line/LineAwardIcon'
import LineTagAltIcon from 'components/Luxkit/Icons/line/LineTagAltIcon'
import LineCardAtmIcon from 'components/Luxkit/Icons/line/LineCardAtmIcon'
import LineCommentsAltIcon from 'components/Luxkit/Icons/line/LineCommentsAltIcon'
import BusinessTravellerHeroSection from './sections/BusinessTravellerHeroSection'

const EMPLOYEE_ADVANTAGES: Array<ComponentProps<typeof BusinessTravellerAdvantageTile>> = [
  {
    IconComponent: LineAwardIcon,
    title: 'Get Rewarded',
    description: 'Earn credit for personal use when you make budget-conscious choices during your business travel.',
  },
  {
    IconComponent: LineCardAtmIcon,
    title: 'Seamless Booking Experience',
    description: 'Search, compare and book flights, accommodation and other travel services for an effortless work trip.',
  },
  {
    IconComponent: LineTagAltIcon,
    title: 'Exclusive Rates',
    description: 'Unlock exclusive discounts and get free inclusions like breakfast and valet parking.',
  },
  {
    IconComponent: LineCommentsAltIcon,
    title: '24/7 Support',
    description: 'Round-the-clock support from our dedicated team.',
  },
]

const AdvantagesContainer = styled(LayoutContainer)`
  padding-top: ${rem(80)};
  padding-bottom: ${rem(80)};
`

const AdvantagesHeadingGroup = styled(Group)`
  max-width: ${rem(560)};
`

function BusinessTravellerEmployeesBenefitsSection() {
  return <section>
    <AdvantagesContainer>
      <Group direction="vertical" gap={32}>
        <AdvantagesHeadingGroup direction="vertical" gap={20}>
          <Heading variant="heading1">How we reward you</Heading>

          <BodyText variant="large">
            Take your journey beyond business with personal rewards and unique benefits and will change the way you work and travel.
          </BodyText>
        </AdvantagesHeadingGroup>

        <BusinessTravellerAdvantagesGroup advantages={EMPLOYEE_ADVANTAGES} />
      </Group>
    </AdvantagesContainer>

    <BusinessTravellerHeroSection
      desktopImageId="bt-sales-employee-booking-@desktop"
      mobileImageId="bt-sales-employee-booking-@mobile"
      type="light-grey"
    >
      <Group direction="vertical" gap={8}>
        <Heading variant="heading2">Booking Made Simple</Heading>
        <BodyText variant="large" colour="neutral-one">
          Effortlessly browse, compare and secure flights, accommodation and other travel services through our user-friendly platform. Simplifying the process of securing your business trip with our seamless booking experience.
        </BodyText>
      </Group>
    </BusinessTravellerHeroSection>

    <BusinessTravellerHeroSection
      isInverted
      isReversed
      desktopImageId="bt-sales-employee-credit-@desktop"
      mobileImageId="bt-sales-employee-credit-@mobile"
    >
      <Group direction="vertical" gap={8}>
        <Heading variant="heading2">Earn Credits for Personal Travel</Heading>
        <BodyText variant="large" colour="neutral-one">
          Our platform rewards your commitment to making budget-conscious choices during your business travel. If you spend less than your company’s travel budget, you’ll earn credit that can be redeemed for personal travel with Luxury Escapes.
        </BodyText>
      </Group>
    </BusinessTravellerHeroSection>

    <BusinessTravellerHeroSection
      desktopImageId="bt-sales-employee-account-@desktop"
      mobileImageId="bt-sales-employee-account-@mobile"
      type="light-grey"
    >
      <Group direction="vertical" gap={8}>
        <Heading variant="heading2">Travel Your Way, Independently</Heading>
        <BodyText variant="large" colour="neutral-one">
          Enjoy the freedom to embark on personal travel adventures with complete confidentiality and independence. Bookings outside of your business-related travels remain entirely separate and exclusively yours to relish.
        </BodyText>
      </Group>
    </BusinessTravellerHeroSection>
  </section>
}

export default BusinessTravellerEmployeesBenefitsSection
